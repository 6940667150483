<template>
  <span>
    <object-link
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CATEGORY_EQUIPMENTS)"
      :object="categoryEquipment"
    />
    <span v-else>{{ $objectDenomination(categoryEquipment) }}</span>
  </span>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "categoryEquipment",

  components: {},

  mixins: [formMixin],

  props: {
    categoryEquipment: {
      type: Object,
      default: () => ({}),
      description: "Category Equipment",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    categoryEquipment(categoryEquipment) {},
  },
};
</script>
