export const EQUIPMENT_STATUS_AVAILABLE = "available";
export const EQUIPMENT_STATUS_UNAVAILABLE = "unavailable";
export const EQUIPMENT_STATUS_ARCHIVED = "archived";


export const equipmentStatusesOption = {
  [EQUIPMENT_STATUS_AVAILABLE]: "Disponible",
  [EQUIPMENT_STATUS_UNAVAILABLE]: "Non Disponible",
  [EQUIPMENT_STATUS_ARCHIVED]: "Archivé",
};

